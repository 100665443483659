<style scoped>
.bmap {
  width: 100%;
  height: 480px;
}
</style>

<template>
  <v-text-field
    :label="label"
    :value="value"
    @change="change"
    filled
    background-color="transparent"
    hide-details
  >
    <template slot="append">
      <v-btn icon tile @click="show = true">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
      <v-dialog v-model="show" max-width="480" persistent>
        <v-card>
          <v-card-title>
            <div>经纬度选择</div>
            <v-spacer></v-spacer>
            <v-btn icon @click="show = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <baidu-map
            v-if="show"
            :center="mapCenter"
            :zoom="zoom"
            :scroll-wheel-zoom="true"
            :mapStyle="mapStyle"
            class="bmap"
            @ready="onReady"
            @click="onClick"
          >
            <bm-marker :position="position"></bm-marker>
          </baidu-map>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="success" @click="submit">确定</v-btn>
            <v-btn text @click="show = false">取消</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-text-field>
</template>

<script>
import mapStyle from "../../utils/map-style.json";
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: ["label", "value"],
  data() {
    return {
      show: false,
      mapStyle: {
        styleJson: mapStyle,
      },
      ready: false,
      mapCenter: { lng: 0, lat: 0 },
      position: { lng: 0, lat: 0 },
      zoom: 11,
      map: null,
    };
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.updateMap();
      },
    },
  },
  destroyed() {
    this.clean();
  },
  methods: {
    clean() {
      if (this.map) this.map.clearOverlays();
      if (window.$BAIDU$) {
        if (window.$BAIDU$._instances) {
          let keys = Object.keys(window.$BAIDU$._instances);
          for (let i in keys) {
            let key = keys[i];
            let instance = window.$BAIDU$._instances[key];
            if (instance == this.map) {
              delete window.$BAIDU$._instances[key];
              if (window.MPC_Mgr) delete window.MPC_Mgr[key];
              if (window.GCJMAP && window.GCJMAP.ba == key)
                delete window.GCJMAP;
            } else if (instance.map == null) {
              delete window.$BAIDU$._instances[key];
            }
          }
        }
      }
      this.map = null;
    },
    onReady(e) {
      this.map = e.map;
      this.ready = true;
      this.updateMap();
    },
    onClick(e) {
      this.position.lng = e.point.lng;
      this.position.lat = e.point.lat;
    },
    updateMap() {
      if (!this.ready) return;
      let lnglat = this.value.split(",");
      let lng = parseFloat(lnglat[0]) || 116.40354;
      let lat = parseFloat(lnglat[1]) || 39.923425;
      this.mapCenter.lng = lng;
      this.mapCenter.lat = lat;
      this.position.lng = lng;
      this.position.lat = lat;
    },
    submit() {
      this.change(`${this.position.lng},${this.position.lat}`);
      this.show = false;
    },
    change(value) {
      this.$emit("change", value);
    },
  },
};
</script>
